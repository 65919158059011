<template>
  <b-form-group>
    <label :for="id" v-if="withLabel">
      <i v-if="isRequired" class="text-danger" style="">*</i>
      {{ $t(id) }}
      <span
        v-if="showRemainChars"
        :class="remainChar < 0 ? 'badge badge-danger' : 'badge badge-primary'"
        >{{ remainChar }}</span
      >
    </label>
    <b-input-group>
      <template #prepend>
        <b-input-group-text v-if="icon != null"
          ><strong :class="iconColor">
            <i :class="icon" v-if="icon != null" /> </strong
        ></b-input-group-text>
      </template>
      <b-form-input
        :type="type"
        :id="id"
        :name="id"
        :autocomplete="autoComplete"
        :value="value"
        @input="(val) => changed(val)"
        :state="validateState(id)"
        :aria-describedby="id + '-feedback'"
        :placeholder="$t(id)"
        @keydown="(e) => keydown(e)"
        @blur="lostFocus()"
      >
      </b-form-input>
      <b-form-invalid-feedback :id="id + '-feedback'">{{
        $t(`${prename}Validator.${id}`)
      }}</b-form-invalid-feedback>
    </b-input-group>
  </b-form-group>
</template>

<script>
export default {
  name: "ValidationableTextBox",
  props: {
    id: { required: true, type: String },
    value: { required: true },
    form: { required: true, type: Object },
    type: { required: false, type: String, default: "text" },
    prename: { required: false, type: String, default: "" },
    withLabel: { required: false, type: Boolean, default: true },
    isRequired: { type: Boolean, default: false },
    icon: { type: String, default: null },
    autoComplete: { type: String, default: "" },
    iconColor: { type: String, default: "text-dark" },
    showRemainChars: { type: Boolean, default: false },
  },
  data() {
    return {
      remainChar: 0,
    };
  },
  watch: {
    value() {
      if (this.showRemainChars) this.getRemainChars(this.value);
    },
  },
  mounted() {
    this.getRemainChars("");
  },
  methods: {
    keydown(e) {
      this.$emit("keydown", e);
    },
    getRemainChars(val) {
      const maxLength = this.form.$params["maxLength"];
      if (this.showRemainChars && maxLength !== undefined) {
        const textLength = val != null ? val.length : 0;
        this.remainChar = maxLength.max - textLength;
      }
    },
    changed(val) {
      this.getRemainChars(val);
      this.$emit("input", val);
    },
    validateState(name) {
      const { $dirty, $error } = this.form;
      return $dirty ? !$error : null;
    },
    lostFocus() {
      this.$emit("onLostFocus");
    },
  },
};
</script>