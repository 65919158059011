<template>
  <b-form-group>
    <label v-if="label != undefined">{{ label }}</label>
    <br v-if="label != undefined" />
    <c-switch
      :class="customClass"
      color="success"
      outline="alt"
      :variant="variant"
      :size="size"
      label
      :checked="loadedValue"
      v-model="loadedValue"
      @change="
        (val) => {
          change(val);
        }
      "
      v-bind="labelIcon"
    />
  </b-form-group>
</template>
<script>
export default {
  name: "CheckBox",
  props: {
    defaultValue: { DataType: Boolean },
    label: String,
    customClass: { type: String, default: "mx-1" },
    variant: { type: String, default: null },
    size: { type: String, default: null },
    labelIcon: {
      type: Object,
      default: () => {
        return {
          dataOn: "\u2713",
          dataOff: "\u2715",
        };
      },
      required: false,
    },
  },
  data() {
    return {
      loadedValue: this.defaultValue,
    };
  },
  watch: {
    defaultValue() {
      this.loadedValue = this.defaultValue;
    },
  },
  methods: {
    change(val) {
      this.$emit("input", val);
    },
  },
};
</script>