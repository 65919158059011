import { required, minLength, maxLength, email, sameAs, requiredIf, requiredUnless } from "vuelidate/lib/validators";

function getValidations() {
    return {
        form: {
            firstName: {
                maxLength: maxLength(50),
            },
            lastName: {
                maxLength: maxLength(50),
            },
            userName: {
                required,
                minLength: minLength(6),
                maxLength: maxLength(50),
            },
            email: {
                email,
                required,
                minLength: minLength(5),
                maxLength: maxLength(50),
            },
            password: {
                required: requiredIf(function (form) {
                    return !form.isUpdating
                }),
                minLength: minLength(6),
                maxLength: maxLength(50),
            },
            repeatPassword: {
                repeatPassword: sameAs('password')
            },
        },
    }
}

export default { getValidations };